import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Container, Grid } from '@mui/material';
import {postRequest} from "../../Fetch/request";
import PopUpProject from "../Popup/PopUpProject"
import Loader from "../Loader/Loader";

const GenerateAssignment = () => {
    // State hooks for each question
    const [personality, setPersonality] = useState([]);
    const [interests, setInterests] = useState([]);
    const [workStyle, setWorkStyle] = useState('');
    const [learningStyle, setLearningStyle] = useState('');
    const [timeAvailability, setTimeAvailability] = useState('');
    const [technology, setTechnology] = useState([]);
    const [problemSolving, setProblemSolving] = useState('');
    const [collaborationInterest, setCollaborationInterest] = useState('');
    const [realWorldProblem, setRealWorldProblem] = useState('');
    const [designImportance, setDesignImportance] = useState('');
    const [preferredTools, setPreferredTools] = useState('');
    const [longTermVision, setLongTermVision] = useState('');

    const [title,setTitle] = useState('');
    const [description,setDescription] = useState('');
    const [objectives,setObjectives] = useState([]);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => setIsPopupOpen(true);

    const [loader,setLoader] = useState(false);
    // Options for dropdowns
    const technologies = ['React', 'Node.js', 'Python', 'Java', 'C++', 'Ruby'];
    const problemSolvingOptions = [
        'Puzzles and Logical Challenges',
        'Design and UX Problems',
        'Social and Community Issues',
        'Automation and Efficiency',
        'Technical and Engineering Challenges'
    ];
    const collaborationOptions = [
        'Solo Project',
        'Partner with One Other Developer',
        'Small Group Project (3-5 people)',
        'Large Group/Community Project'
    ];
    const realWorldProblems = [
        'Yes, something that impacts my local community',
        'Yes, something that could scale globally',
        'Not necessarily, I’m more interested in learning or experimenting'
    ];
    const designImportanceOptions = [
        'Extremely Important (Focus on UI/UX)',
        'Important, but secondary to functionality',
        'Not very important (Focus on backend, algorithms, etc.)',
        'Not important at all (Command-line tools, scripts)'
    ];
    const preferredToolsOptions = [
        'Yes, specify:',
        'No, I’m open to suggestions'
    ];
    const longTermVisionOptions = [
        'Yes, potentially a startup or product',
        'Yes, as a long-term hobby or open-source project',
        'No, just a one-off learning project',
        'Not sure'
    ];

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoader(true);

        // Create the request body
        const requestBody = {
            'Personality': personality,
            'Interests': interests,
            'Work Style': workStyle,
            'Learning Style':learningStyle,
            'Time Availability':   timeAvailability,
            'Preferred Technology':  technology,
            'Type of Problem to Solve':problemSolving,
            'Collaboration Interest':  collaborationInterest,
            'Real-World Problem Focus': realWorldProblem,
            'Importance of Design': designImportance,
            'Preferred Tools':preferredTools,
            'Long-Term Vision':  longTermVision
        };

        let postResponse = await postRequest(process.env.REACT_APP_URL_AI + "/generate_project", requestBody);
        if( 'title' in postResponse && 'description' in postResponse && 'objectives' in postResponse && postResponse.title && postResponse.description && postResponse.objectives ) {
            setTitle(postResponse.title);
            setDescription(postResponse.description);
            setObjectives(postResponse.objectives);
            openPopup();
        }
        setLoader(false);
    };

    return (
        ( !isPopupOpen ?
            (<Container>
                {loader ? <Loader/> : <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{"textAlign":"center",marginBottom:"20px"}}>
                            <Button variant="contained" color="primary" type="submit" >
                                Generate Project
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="personality-label">Personality</InputLabel>
                                <Select
                                    labelId="personality-label"
                                    multiple
                                    value={personality}
                                    onChange={(e) => setPersonality(e.target.value)}
                                    renderValue={(selected) => selected.join(', ')}
                                    label="Personality"
                                >
                                    <MenuItem value="Creative">Creative</MenuItem>
                                    <MenuItem value="Analytical">Analytical</MenuItem>
                                    <MenuItem value="Organized">Organized</MenuItem>
                                    <MenuItem value="Detail-Oriented">Detail-Oriented</MenuItem>
                                    <MenuItem value="Flexible">Flexible</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="interests-label">Interests</InputLabel>
                                <Select
                                    labelId="interests-label"
                                    multiple
                                    value={interests}
                                    onChange={(e) => setInterests(e.target.value)}
                                    renderValue={(selected) => selected.join(', ')}
                                    label="Interests"
                                >
                                    <MenuItem value="Web Development">Web Development</MenuItem>
                                    <MenuItem value="Data Science">Data Science</MenuItem>
                                    <MenuItem value="Game Development">Game Development</MenuItem>
                                    <MenuItem value="Machine Learning">Machine Learning</MenuItem>
                                    <MenuItem value="Mobile Apps">Mobile Apps</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="workstyle-label">Work Style</InputLabel>
                                <Select
                                    labelId="workstyle-label"
                                    value={workStyle}
                                    onChange={(e) => setWorkStyle(e.target.value)}
                                    label="Work Style"
                                >
                                    <MenuItem value="Independent">Independent</MenuItem>
                                    <MenuItem value="Collaborative">Collaborative</MenuItem>
                                    <MenuItem value="Mixed">Mixed</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="learningstyle-label">Learning Style</InputLabel>
                                <Select
                                    labelId="learningstyle-label"
                                    value={learningStyle}
                                    onChange={(e) => setLearningStyle(e.target.value)}
                                    label="Learning Style"
                                >
                                    <MenuItem value="Visual">Visual</MenuItem>
                                    <MenuItem value="Auditory">Auditory</MenuItem>
                                    <MenuItem value="Kinesthetic">Kinesthetic</MenuItem>
                                    <MenuItem value="Reading/Writing">Reading/Writing</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="timeavailability-label">Time Availability</InputLabel>
                                <Select
                                    labelId="timeavailability-label"
                                    value={timeAvailability}
                                    onChange={(e) => setTimeAvailability(e.target.value)}
                                    label="Time Availability"
                                >
                                    <MenuItem value="Full-Time">Full-Time</MenuItem>
                                    <MenuItem value="Part-Time">Part-Time</MenuItem>
                                    <MenuItem value="Flexible">Flexible</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="technology-select-label">Select Technology</InputLabel>
                                <Select
                                    labelId="technology-select-label"
                                    multiple
                                    value={technology}
                                    onChange={(e) => setTechnology(e.target.value)}
                                    renderValue={(selected) => selected.join(', ')}
                                    label="Select Technology"
                                >
                                    {technologies.map((tech) => (
                                        <MenuItem key={tech} value={tech}>
                                            {tech}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="problem-solving-label">Problem Solving</InputLabel>
                                <Select
                                    labelId="problem-solving-label"
                                    value={problemSolving}
                                    onChange={(e) => setProblemSolving(e.target.value)}
                                    label="Problem Solving"
                                >
                                    {problemSolvingOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="collaborationinterest-label">Collaboration Interest</InputLabel>
                                <Select
                                    labelId="collaborationinterest-label"
                                    value={collaborationInterest}
                                    onChange={(e) => setCollaborationInterest(e.target.value)}
                                    label="Collaboration Interest"
                                >
                                    {collaborationOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="realworldproblem-label">Real-World Problem</InputLabel>
                                <Select
                                    labelId="realworldproblem-label"
                                    value={realWorldProblem}
                                    onChange={(e) => setRealWorldProblem(e.target.value)}
                                    label="Real-World Problem"
                                >
                                    {realWorldProblems.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="designimportance-label">Design Importance</InputLabel>
                                <Select
                                    labelId="designimportance-label"
                                    value={designImportance}
                                    onChange={(e) => setDesignImportance(e.target.value)}
                                    label="Design Importance"
                                >
                                    {designImportanceOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="preferredtools-label">Preferred Tools</InputLabel>
                                <Select
                                    labelId="preferredtools-label"
                                    value={preferredTools}
                                    onChange={(e) => setPreferredTools(e.target.value)}
                                    label="Preferred Tools"
                                >
                                    {preferredToolsOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{marginBottom: '20px'}}>
                                <InputLabel id="longtermvision-label">Long-Term Vision</InputLabel>
                                <Select
                                    labelId="longtermvision-label"
                                    value={longTermVision}
                                    onChange={(e) => setLongTermVision(e.target.value)}
                                    label="Long-Term Vision"
                                >
                                    {longTermVisionOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                </form>}
            </Container>) :
                (<PopUpProject title={title} description={description} objectives={objectives}
                               setIsPopupOpen={setIsPopupOpen}/>)
        )
    );
};

export default GenerateAssignment;
