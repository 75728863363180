import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
}));

export default function PopUpProject({ title, description, objectives, setIsPopupOpen }) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        setIsPopupOpen(false);
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 3, fontWeight: 'bold', fontSize: '1.5rem' }} id="customized-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#007bff' }}>
                        Description
                    </Typography>
                    <Typography gutterBottom sx={{ mb: 3 }}>
                        {description}
                    </Typography>
                    <Divider sx={{ mb: 3 }} />
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#007bff' }}>
                        Objectives
                    </Typography>
                    <ul style={{ paddingLeft: '20px' }}>
                        {objectives.map((objective, index) => (
                            <li key={index} style={{ marginBottom: '10px', fontSize: '1rem', color: '#333' }}>
                                {objective}
                            </li>
                        ))}
                    </ul>
                </DialogContent>
                <DialogActions sx={{ flexDirection: 'column', alignItems: 'flex-start', padding: '16px' }}>
                    <Typography sx={{ color: '#d32f2f', mb: 2 }}>
                        Please take a snapshot of this project. It will be removed once you close this window.
                    </Typography>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Got it, Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
