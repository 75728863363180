import React from "react";
import "./style.css"
const HackathonPage = () => {
    return (
            <div className="container">
                <h2>
                    Hackathons <br/> Coming Soon
                </h2>
            </div>
    );
}
export default HackathonPage;
