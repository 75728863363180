export const fetchData = async (url, options) => {
  try {
    const response = await fetch(url, options);
    return await response.json();
  } catch (error) {
    console.error('Error:', error);
    throw error; // Rethrow the error to let the calling code handle it
  }
};

export const postRequest = async (url  , bodyObject) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify(bodyObject),
  };

  try {
    return await fetchData(url, options);
  } catch (error) {
    // Handle the error
  }
};

export const getRequest = async (url) => {
  try {
    return await fetchData(url);
  } catch (error) {
    // Handle the error
  }
};