import './Card.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";

export default function CardObject({ articleTitle, writer, id }) {
    return (
        <Card sx={{ width: 250, height: 200, cursor:"pointer" }}
              onClick={() => {
            window.location.href = '/write?preview=true&id=' + id
        }}>
            <img
                style={{ height: "145px",width:"345px" }}
                src={"images/notes.png"}
                title="green iguana"
            />

            <CardContent>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign:'center'
                    }}

                >
                    {articleTitle}
                </Typography>
            </CardContent>
        </Card>
    );
}
