import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import ReactCardFlip from 'react-card-flip';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';

const SlideFlipCard = ({ question, answer }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <Card
                onClick={handleFlip}
                style={{ cursor: 'pointer', height:'auto', padding:'10px', width: '300px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
            >
                <CardContent
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        backgroundColor: '#f0f0f0'
                    }}
                >
                    <Typography variant="h5" component="div">
                        {question}
                    </Typography>
                </CardContent>
            </Card>

            <Card
                onClick={handleFlip}
                style={{ cursor: 'pointer', minHeight: '300px', backgroundColor: '#e0f7fa', overflowY: "auto", width: '300px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
            >
                <CardContent
                    variant="h5" component="div"
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#e0f7fa',
                        height:'300px', width:"100%",
                        fontSize:'1.2em',
                        padding:'20px'
                    }}
                >
                        {answer}
                </CardContent>
            </Card>
        </ReactCardFlip>
    );
};

const SlideFlipCardContainer = (cardsData) => {
    const [currentCard, setCurrentCard] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    const animationProps = useSpring({
        opacity: isAnimating ? 0 : 1,
        transform: isAnimating ? 'translateX(-100%)' : 'translateX(0%)',
        config: { tension: 200, friction: 20 },
        onRest: () => setIsAnimating(false),
    });

    const handleNext = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setCurrentCard((prev) => (prev + 1) % cardsData.cardsData.length);
        }, 300); // Match this duration with your animation
    };

    return (
        (cardsData!=null && cardsData.cardsData.length > 0 && <Box textAlign="center">
            <animated.div style={animationProps}>
                <SlideFlipCard
                    question={cardsData.cardsData[currentCard].question}
                    answer={cardsData.cardsData[currentCard].answer}
                />
            </animated.div>
            <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
                style={{ marginTop: '20px' }}
            >
                Next Question
            </Button>
        </Box>)
    );
};

export default SlideFlipCardContainer;
