import React, { useState } from 'react';
import { Typography, TextField, Button, Grid, Paper, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const Comment = ({ comment, onUpvote, onDownvote }) => {
    return (
        <Paper style={{ paddingTop: '10px', paddingLeft:"10px" , paddingRight:"10px", paddingBottom:"0px", marginBottom: '10px' }}>
            <div style={{height:"100%"}}>
            <Typography variant="body1">{comment.content}</Typography>
            </div>
            <div style={{display:"flex" , justifyContent:"space-between" , paddingBottom:"0px"}}>
                <div style={{lineHeight:"3"}}>
            <Typography variant="caption">{comment.email}</Typography>
                </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton color="primary" onClick={() => onUpvote(comment.id)}>
                    <ThumbUpIcon />
                </IconButton>
                <Typography>{comment.upvotes}</Typography>
                <IconButton color="secondary" onClick={() => onDownvote(comment.id)}>
                    <ThumbDownIcon />
                </IconButton>
                <Typography>{comment.downvotes}</Typography>
            </div>
            </div>
        </Paper>
    );
};

const DiscussionForum = () => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handleUserEmailChange = (e) => {
        setUserEmail(e.target.value);
    };

    const handleAddComment = () => {
        if (newComment.trim() !== '' && userEmail.trim() !== '') {
            setComments((prevComments) => [
                ...prevComments,
                { id: Date.now(), content: newComment, email: userEmail, upvotes: 0, downvotes: 0 },
            ]);
            setNewComment('');
            setUserEmail('');
        }
    };

    const handleUpvote = (commentId) => {
        setComments((prevComments) =>
            prevComments.map((comment) =>
                comment.id === commentId ? { ...comment, upvotes: comment.upvotes + 1 } : comment
            )
        );
    };

    const handleDownvote = (commentId) => {
        setComments((prevComments) =>
            prevComments.map((comment) =>
                comment.id === commentId ? { ...comment, downvotes: comment.downvotes + 1 } : comment
            )
        );
    };

    return (
        <div style={{ display: 'flex', height:"80vh", marginBottom:"5%",marginTop:"1%" }}>
            <div style={{ flex: 1, height:"100%", overflowY:"scroll", padding:"1%" }}>
                <Typography variant="h5">Problem 1</Typography>
                {comments.map((comment) => (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        onUpvote={handleUpvote}
                        onDownvote={handleDownvote}
                    />
                ))}
            </div>
            <div style={{ padding: '20px', width: '300px' }}>
                <Typography variant="h5">Add a Comment</Typography>
                <TextField
                    multiline
                    rows={15}
                    fullWidth
                    placeholder="Type your comment here..."
                    value={newComment}
                    onChange={handleCommentChange}
                    style={{ marginBottom: '10px' }}
                />
                <TextField
                    fullWidth
                    placeholder="Your Email"
                    value={userEmail}
                    onChange={handleUserEmailChange}
                    style={{ marginBottom: '10px' }}
                />
                <Button variant="contained" color="primary" onClick={handleAddComment}>
                    Add Comment
                </Button>
            </div>
        </div>
    );
};

export default DiscussionForum;
