import React, { useState } from 'react';
import { FormControl, InputLabel, useMediaQuery, TextField } from '@mui/material';
import SlideFlipCardContainer from './SlideFlipCardContainer';
import { Select, Box, MenuItem, IconButton } from '@mui/material';
import Quiz from './Quiz';
import CachedIcon from '@mui/icons-material/Cached';
import { getRequest } from "../../Fetch/request";
import Loader from "../Loader/Loader";

const CoreSubjects = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [selectedTestType, setSelectedTestType] = useState('');
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [flashCards, setFlashCards] = useState([]);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [isLoader, setLoader] = useState(false);

    const handleTopicChange = (event) => {
        setSelectedTopic(event.target.value);
        handleTopic(event.target.value);
    };

    const handleTestTypeChange = (event) => {
        setSelectedTestType(event.target.value);
        handleTestType(event.target.value);
    };

    const handleTagChange = (event) => {
        setTagInput(event.target.value);
    };

    const handleAddTag = () => {
        if (tagInput && !tags.includes(tagInput)) {
            setTags([...tags, tagInput]);
            setTagInput('');
        }
    };

    const handleRemoveTag = (tag) => {
        setTags(tags.filter(t => t !== tag));
    };

    const handleTopic = (topic) => {
        console.log(`Selected Topic: ${topic}`);
        // Implement your logic here
    };

    const handleTestType = (testType) => {
        console.log(`Selected Test Type: ${testType}`);
        // Implement your logic here
    };

    const handleSubmit = async () => {
        setLoader(true);
        setQuizQuestions([]);
        setFlashCards([]);

        if (selectedTopic !== '' && selectedTestType !== '') {
            const tagsQuery = tags.length > 0 ? `&tags=${tags.join(',')}` : '&tags=';
            let getResponse = await getRequest(`${process.env.REACT_APP_URL_AI}/${selectedTestType}?topic=${selectedTopic}${tagsQuery}`);
            if (selectedTestType === "flashcard") {
                setFlashCards(getResponse);
            } else {
                setQuizQuestions(getResponse);
            }
        }
        setLoader(false);
    };

    return (
        <div style={{ backgroundColor: "#eee" }}>
            <Box textAlign="center" mt={1} style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "block" }}>
                    <FormControl variant="outlined" style={{ minWidth: 200, margin: '0 10px' }}>
                        <InputLabel id="topic-select-label">Select Topic</InputLabel>
                        <Select
                            labelId="topic-select-label"
                            value={selectedTopic}
                            onChange={handleTopicChange}
                            label="Select Topic"
                        >
                            <MenuItem value="Operating System">Operating System</MenuItem>
                            <MenuItem value="Object Oriented Programming">Object Oriented Programming</MenuItem>
                            <MenuItem value="Networking">Networking</MenuItem>
                            <MenuItem value="System Design">System Design</MenuItem>
                            <MenuItem value="DBMS">DBMS</MenuItem>
                        </Select>
                    </FormControl>
                    {isSmallScreen && <br />}
                    {isSmallScreen && <br />}
                    <FormControl variant="outlined" style={{ minWidth: 200, margin: '0 10px' }}>
                        <InputLabel id="test-type-select-label">Select Test Type</InputLabel>
                        <Select
                            labelId="test-type-select-label"
                            value={selectedTestType}
                            onChange={handleTestTypeChange}
                            label="Select Test Type"
                        >
                            <MenuItem value="flashcard">Flash Card</MenuItem>
                            <MenuItem value="quiz">MCQ Test</MenuItem>
                        </Select>
                    </FormControl>

                    <Box textAlign="center" mt={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextField
                            variant="outlined"
                            label="Add Tags"
                            value={tagInput}
                            onChange={handleTagChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleAddTag();
                                }
                            }}
                            style={{ width: '200px', marginBottom: '10px' }}
                        />
                        <div>
                            {tags.map(tag => (
                                <span
                                    key={tag}
                                    style={{
                                        display: 'inline-block',
                                        padding: '5px 10px',
                                        margin: '0 5px',
                                        backgroundColor: '#ccc',
                                        borderRadius: '3px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleRemoveTag(tag)}
                                >
                            {tag} ×
                        </span>
                            ))}
                        </div>
                    </Box>

                </div>
                <IconButton
                    onClick={handleSubmit}
                    color="primary"
                    style={{ marginLeft: '10px', padding: '10px' }}
                >
                    <CachedIcon />
                </IconButton>
            </Box>


            <hr />
            {flashCards.length > 0 && <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60vh',
            }}>
                <SlideFlipCardContainer cardsData={flashCards} />
            </div>}
            {quizQuestions.length > 0 && <div style={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Quiz quizData={quizQuestions} />
            </div>}
            {isLoader && <Loader />}
        </div>
    );
}

export default CoreSubjects;
