import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p:1,
  borderRadius:"10%",
  textAlign:"center"
};

const PopUp = ({open, handleClose, popUpTitle, popUpMsg, popUpColor}) => {
  return (
      <div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography id="transition-modal-title" variant="h6" component="h2" style={{color:popUpColor}}>
                  {popUpTitle}
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  {popUpMsg}
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>
  );
}

export default PopUp;