import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Dialog, DialogTitle,
    DialogContent, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Button,
    Select, MenuItem, InputLabel, FormControl
} from '@mui/material';

export default function RoadMap() {
    const [technologies] = useState([
        'React', 'Python', 'AngularJs', 'C++', 'CNN',
        'DBMS', 'DESIGNPATTERN', 'GO', 'Java', 'Javascript',
        'Kotlin', 'MongoDB', 'NLP', 'RNN', 'OOPS',
        'Rust', 'SOLID', 'SQL', 'NextJS', 'NodeJS', 'Typescript'
    ]);
    const [selectedTechnology, setSelectedTechnology] = useState('');
    const [roadmap, setRoadmap] = useState([]);
    const [filteredRoadmap, setFilteredRoadmap] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        if (selectedTechnology) {
            fetch(`roadmap/${selectedTechnology.toLowerCase()}.json`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    setRoadmap(data);
                    setFilteredRoadmap(data);
                    setError('');
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setError('Failed to fetch data');
                });
        }
    }, [selectedTechnology]);

    const handleClickOpen = (topic) => {
        setSelectedTopic(topic);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setSelectedTopic(null);
    };

    return (
        <Box sx={{
            padding: '10px',
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <FormControl sx={{ marginBottom: '20px', width: '200px' }}>
                <InputLabel id="technology-select-label">Select Technology</InputLabel>
                <Select
                    labelId="technology-select-label"
                    value={selectedTechnology}
                    onChange={(e) => setSelectedTechnology(e.target.value)}
                    label="Select Technology"
                >
                    <MenuItem value="">
                        <em>Select Technology</em>
                    </MenuItem>
                    {technologies.map((tech) => (
                        <MenuItem key={tech} value={tech}>
                            {tech}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {error && (
                <Typography color="error" sx={{ marginBottom: '20px' }}>
                    {error}
                </Typography>
            )}

            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "stretch",
                height: "100%",
                gap: '20px'
            }}>
                {filteredRoadmap.length > 0 ? (
                    filteredRoadmap.map((item) => (
                        <Box
                            key={item.topic}
                            onClick={() => handleClickOpen(item)}
                            sx={{
                                width: {
                                    xs: '100px',  // small screens
                                    sm: '150px',  // medium screens
                                    md: '200px'   // large screens
                                },
                                height: {
                                    xs: '100px',  // small screens
                                    sm: '150px',  // medium screens
                                    md: '200px'   // large screens
                                },
                                backgroundColor: '#008080',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                transition: 'transform 0.2s ease-in-out, background-color 0.2s',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                '&:hover': {
                                    backgroundColor: '#006d6d',
                                    transform: 'scale(1.05)',
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: {
                                        xs: '0.75rem',  // small screens
                                        sm: '1rem',     // medium screens
                                        md: '1.25rem'   // large screens
                                    }
                                }}
                            >
                                {item.topic}
                            </Typography>
                        </Box>
                    ))
                ) : (<br/>)}
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                sx={{ padding: '20px' }}
            >
                <DialogTitle sx={{ backgroundColor: '#004c4c', color: '#fff', textAlign: 'center' }}>
                    {selectedTopic?.topic || 'Topic Details'}
                </DialogTitle>
                <DialogContent>
                    {selectedTopic ? (
                        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#004c4c' }}>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Subtopic</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Project</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedTopic.subtopics.map((subtopic, subIndex) => (
                                        <TableRow key={subIndex}>
                                            <TableCell sx={{ color: '#333', fontWeight: 'bold' }}>{subtopic.subtopic}</TableCell>
                                            <TableCell sx={{ color: '#555' }}>{subtopic.project}</TableCell>
                                            <TableCell sx={{ color: '#777' }}>{subtopic.description}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography>No details available</Typography>
                    )}
                    <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            href="https://chat.openai.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Ask ChatGPT to Elaborate the Description of the Project
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
