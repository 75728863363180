import React, {useEffect, useState} from 'react';
import {getRequest} from "../../Fetch/request";
import CardObject from './Card'
import './MyBlogs.css'
import Loader from "../Loader/Loader";

const MyBlogs = ({ articleTitle, articleComponent }) => {
    const [cards ,setCards] = useState([]);
    const [isFetch, setIsFetch] = useState(false);

    useEffect(() => {
        const fetchAllBlogs = async () => {
            let getResponse = await getRequest(process.env.REACT_APP_URL + "/blog/getAllBlogs?email=" + localStorage.getItem('email'));
            setCards(getResponse["response"]);
            setIsFetch(true);
        };
        fetchAllBlogs();
    }, []);

    return (
        ( isFetch === true) ?

           cards.length > 0 ? (
        <div style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection: "column"
        }}>
            <div className="square-container">
                {cards.map(card=><CardObject className="square" articleTitle={card.title} writer={card.author} id={card.id} />)}
            </div>
        </div>) : (
               <div style={{width:"100%",textAlign:"center",fontWeight:"bold"}}>
                   <h2>No Notes Available<br/>Create Your Notes in Write Section</h2>
               </div>
           )
        :
    (<Loader/>)
    );
};

export default MyBlogs;
