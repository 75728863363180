
process.env.AWS_SDK_LOAD_CONFIG = '1';
const aws = require('aws-sdk');
const uuid = require('uuid');

function generateRandomName() {
    // Generate a v4 (random) UUID
    const randomName = uuid.v4();

    return randomName;
}

const bucketName = process.env.REACT_APP_BUCKET_NAME;
const bucketRegion = process.env.REACT_APP_BUCKET_REGION;
const bucketKey = process.env.REACT_APP_ACCESS_KEY;
const bucketSKey = process.env.REACT_APP_SECRET_KEY;

aws.config.update({
    accessKeyId: bucketKey,
    secretAccessKey: bucketSKey,
    region: bucketRegion,
});

const s3 = new aws.S3();

async function generateS3Url(){
    const uploadParams = {
        Bucket: bucketName,
        Key: generateRandomName(),
        Expires: 60
    }
    return await s3.getSignedUrlPromise('putObject', uploadParams);
}

export default generateS3Url