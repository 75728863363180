import React, {useEffect, useRef, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import { createTheme } from '@mui/material/styles';
import "./Profile.css"
import PopupForm from "./PopupForm";
import {postRequest} from "../../Fetch/request";
import Loader from "../Loader/Loader";
import PopUp from "../Popup/PopUp";

const ProfilePage = () => {
    // const formObject = JSON.parse(localStorage.getItem("profileObject"));
    // const [formData, setFormData] = useState({
    //     email: formObject.email,
    //     name: formObject.name,
    //     designation:  formObject.designation,
    //     company:  formObject.company,
    //     college:  formObject.college,
    //     cgpa:  formObject.cgpa,
    //     leetcode:  formObject.leetcode,
    //     github:  formObject.links[0].link,
    //     twitter: formObject.links[2].link,
    //     linkedin: formObject.links[1].link
    // });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [uploading,setUploading] = useState(0);

    const handleSubmit = async () => {
        // Handle form submission here

        setUploading(1);

        const userObject = {
            name: formData.name,
            email: localStorage.getItem("email"),
            designation: formData.designation,
            company: formData.company,
            college: formData.college,
            leetcode: formData.leetcode,
            links: [
                {
                    "name": "github",
                    "tag": "personal",
                    "link": formData.github,
                },
                {
                    "name": "linkedin",
                    "tag": "personal",
                    "link": formData.linkedin,
                },
                {
                    "name": "twitter",
                    "tag": "personal",
                    "link": formData.twitter,
                }
            ]
        };
        let postResponse = await postRequest(process.env.REACT_APP_URL + "/user/updateUserInfo", userObject);
        setUploading(0);
        if (postResponse.status === "200") {
            localStorage.setItem("email", formData.email);
            localStorage.setItem("profileObject",JSON.stringify(userObject));
        }
        handleClose(); // Close the dialog
    };

    const theme = createTheme({
        typography: {
            fontFamily: 'Roboto, sans-serif',
        },
        palette: {
            primary: {
                main: '#1976d2',
            },
        },
    });

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [formData, setFormData] = useState(null); // Initialize formData as null

    useEffect(() => {
        const fetchData = async () => {
            const profileData = JSON.parse(localStorage.getItem("profileObject"));
            if (profileData) {
                setFormData({ // Set formData only if profileData is not null
                    email: profileData.email,
                    name: profileData.name,
                    designation: profileData.designation,
                    company: profileData.company,
                    college: profileData.college,
                    cgpa: profileData.cgpa,
                    leetcode: profileData.leetcode,
                    github: profileData.links[0].link,
                    twitter: profileData.links[2].link,
                    linkedin: profileData.links[1].link
                });
            }
        };
        fetchData();
    }, []);

    // Render loader if formData is null
    if (!formData) {
        return (<>< Loader/></>)
    }

    return (
            <Card className="cardStyle" >
                {uploading === 1 && <PopUp open={true} handleClose={handleClose} popUpTitle={"Updating"} popUpMsg={"Info is Updating"} popUpColor={"#008080"} />}
            <CardContent sx={{width: "100%"}}>
                <PopupForm open={open} onClose={handleClose} formData={formData} handleChange={handleChange} handleSubmit={handleSubmit}/>
                <Box className="topBox">
                    <Box className="topBoxLeft">
                        <Avatar alt="User Avatar" src={"/images/logo_without_background.png"} sx={{ width: 100, height: 100, mb: 2 }} />
                        <Typography variant="h5" gutterBottom sx={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                        }}>
                            {formData.name}
                        </Typography>
                    </Box>

                    <Box className="topBoxRight">
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                            <Typography variant="body1" gutterBottom sx={{ flex: 1, fontWeight: 'bold', textAlign: "center" }}>
                                College:
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{
                                flex: 1,
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                lineClamp: 2,
                            }}>
                                <span>{formData.college}</span>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                            <Typography variant="body1" gutterBottom sx={{ flex: 1, fontWeight: 'bold', textAlign: "center" }}>
                                Company:
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{
                                flex: 1,
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                lineClamp: 2,
                            }}>
                                <span>{formData.company}</span>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                            <Typography variant="body1" gutterBottom sx={{ flex: 1, fontWeight: 'bold', textAlign: "center" }}>
                                Designation:
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{
                                flex: 1,
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                lineClamp: 2,
                            }}>
                                <span>{formData.designation}</span>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                            <Typography variant="body1" gutterBottom sx={{ flex: 1, fontWeight: 'bold', textAlign: "center" }}>
                                CGPA:
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{
                                flex: 1,
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                lineClamp: 2,
                            }}>
                                <span>{formData.cgpa}</span>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                            <Typography variant="body1" gutterBottom sx={{ flex: 1, fontWeight: 'bold', textAlign: "center" }}>
                                LeetCode:
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{
                                flex: 1,
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                lineClamp: 2,
                            }}>
                                <span>{formData.leetcode}</span>
                            </Typography>
                        </Box>
                    </Box>


                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    width: '100%',
                    mt: "2%"
                }}>
                    <IconButton color="primary" href={formData.linkedin}>
                        <LinkedInIcon/>
                    </IconButton>
                    <IconButton color="primary" href={formData.twitter}>
                        <TwitterIcon/>
                    </IconButton>
                    <IconButton color="primary" href={formData.github}>
                        <GitHubIcon/>
                    </IconButton>
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                        Edit Profile
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProfilePage;
