import React, {useEffect, useState} from 'react';
import { FaArrowDown } from 'react-icons/fa';
import './SubApplication.css';
import {getRequest} from "../../Fetch/request";
import Loader from "../Loader/Loader";

const ButtonList = ({subApplications} ) => {
    const handleSubmit = (id) =>{
        window.location.href = "/microservices?subApplicationId=" + id;
    }
    return (
        <div className="button-list">
            {subApplications.map((subApplication, index) => (
                <React.Fragment key={index}>
                    <button className="button" onClick={()=>handleSubmit(subApplication.id)}>{subApplication.title}</button>
                    {index < subApplications.length - 1 && <FaArrowDown className="arrow-icon" />}
                </React.Fragment>
            ))}
        </div>
    );
};

const SubApplication = () => {

    const [applicationTitle, setApplicationTitle] = useState("");
    const [applicationDescription, setApplicationDescription] = useState("");
    const [subApplications, setSubApplications] = useState([]);

    useEffect(() => {
        const url = window.location.href;
        const id = url.split('?').pop().split("=").pop();
        fetchAllSubApplication(id);
    }, []);

    const fetchAllSubApplication = async (id) => {
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/assignment/getSubApplications?applicationId=" + id);
        if( getResponse.status === "200" && getResponse["response"] != null ) {
            if(  getResponse["response"].length > 0 ){
                setApplicationTitle(getResponse["response"][0]["appTitle"]);
                setApplicationDescription(getResponse["response"][0]["appDescription"]);
            }
            setSubApplications(getResponse["response"]);
        }
    };

    return (
        (subApplications.length > 0) ?
            (<div className="container">
                <div className="side-by-side">
                    <div className="box">
                        <h2>{applicationTitle}</h2>
                        <br/>
                        <p className="description">{applicationDescription}</p>
                    </div>
                    <div className="box">
                        <ButtonList subApplications={subApplications}/>
                    </div>
                </div>
        </div>)
            :
            (<Loader/>)
    )
};

export default SubApplication;

