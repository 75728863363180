import React from "react";
import styled from 'styled-components';

const CustomButton = styled.button`
    /* Base styles for the button */
    width: 180px;
    height: 180px;
    color: black;
    border-radius: 16px;
    font-size: 20px;
    cursor: pointer;
    margin: 5%;
    display: inline-block;
    font-weight: bold;
    background-size: cover;
    

    /* Hover effect */

    &:hover {
        background-color: white;
        transition: transform 0.3s ease-in-out;
    }

    /* Media query for smaller screens */
    @media (max-width: 450px) {
        width: 120px;
        height: 120px;
        font-size: 14px;
    }
    @media (max-width: 305px) {
        width: 100px;
        height: 100px;
        font-size: 12px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;

    @media (max-width: 450px) {
        width: 120px;
    }
    @media (max-width: 305px) {
        width: 100px;
    }
`;

const ButtonText = styled.div`
    margin-top: 8px;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 16px;

    @media (max-width: 450px) {
        font-size: 12px;
    }
    @media (max-width: 305px) {
        font-size: 10px;
    }
`;

export default function CardComponent({ id, handleClick,title,githubLink }) {
    return (
        <ButtonContainer>
            <CustomButton style={{backgroundImage:`url(/images/assignments/${id}.png)`}}  onClick={() => { githubLink? handleClick(githubLink):handleClick(id) }}>
            </CustomButton>
            <ButtonText>
                {title}
            </ButtonText>
        </ButtonContainer>
    );
}
