import * as React from "react";
import {useEffect, useRef, useState} from "react";

export default function TextArea({
                                     handleInputChange,
                                     value,
                                     keyIndex
                                 }) {


    const [height, setHeight] = useState('auto');
    const textareaRef = useRef(null);
    const [stateChange, setStateChange] = useState(0);


    const setH = () =>{
        if( textareaRef.current.value === ""){
            setHeight("auto");
        }else {
            setHeight(`${textareaRef.current.scrollHeight}px`);
        }
    }

    useEffect(() => {
        setH();
    }, [stateChange]);

    useEffect(()=>{
        let myDiv =  document.getElementById('textArea' + keyIndex);
        // Set the height of the div based on its text content


        // Function to set height based on text content
        function setHeightBasedOnText(myDiv) {
            let textContent = myDiv.textContent || myDiv.innerText;
            let textHeight = getTextHeight(textContent);
            myDiv.style.height = textHeight + 'px';
        }



        // Function to measure text height
        function getTextHeight(text) {
            let tempDiv = document.createElement('div');
            tempDiv.style.visibility = 'hidden';
            tempDiv.style.position = 'absolute';
            tempDiv.style.width = myDiv.offsetWidth + 'px'; // Set the width to match the original div
            tempDiv.innerHTML = text;
            return tempDiv.offsetHeight;
        }

     //   setHeightBasedOnText(myDiv);
    },[])

    return (
        <div id={"textArea" + keyIndex}
            style={{position: "relative"}}>
        <textarea
            ref={textareaRef}
            id={"textarea" + toString(keyIndex)}
            style={{
                width: '100%',
                height: height,
                overflow: 'hidden',
                fontSize: '18px',
                color: '#333',
                textAlign: 'justify',
                wordBreak: 'break-all',
                backgroundColor:"#eee",
                border:"none",
                outline: "none",
                paddingRight:"10%"
            }}
            placeholder="Free Your Mind"
            onChange={(e) => {
                handleInputChange(e);
                setStateChange(!stateChange);
            }}
            value={value}
        />
        </div>
    );
}
