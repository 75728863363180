import * as React from "react";
import {useEffect, useState} from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ImageUpload({
  handleImageChange,
  removeImage,
  image
}) {


  const [imgSrc, setImgSrc] = useState(image);

  function setImageSource(source) {
    // Check if the source is a File object
    if (source instanceof File) {
      // File object, set the source as a data URL
      var reader = new FileReader();
      reader.onload = function (loadEvent) {
        setImgSrc(loadEvent.target.result);
      };
      reader.readAsDataURL(source);
    }
    // Check if the source is a string (URL)
    else if (typeof source === 'string') {
      // String URL, set the source directly
      setImgSrc(source);
    }
    // Handle other cases if needed
    else {
      console.error('Unsupported source type');
    }
  }

  useEffect(() => {
    setImageSource(image);
  }, [image]);


  return (
    <div>
      {image && (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <div
            style={{
              position: "absolute",
              top: "-2%",
              right: "29%",
              display: "flex",
            }}
          >
            <div>
              <CloseIcon
                style={{ border:"1px solid red", color: "red", cursor: "pointer" , borderRadius:"50%" }}
                onClick={removeImage}
              />
            </div>
          </div>
          <img src={imgSrc} alt="Selected" style={{width:"40%" , height:"40%" , border:"1px solid black"}} />
          {/* You can display other information about the selected file here */}
        </div>
      )}
    </div>
  );
}
