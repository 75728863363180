import React, {useEffect, useRef, useState} from 'react';
import './Problem.css';
import {getRequest} from "../../Fetch/request"; // Import CSS file for styling
import PopUp from "../Popup/PopUp";

const Problem = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [popUpTitle, setPopUpTitle] = React.useState("");
    const [popUpMsg , setPopUpMsg] = React.useState("");
    const [popUpColor , setPopUpColor] = React.useState("red");

    const [microserviceTitle, setMicroserviceTitle] = useState("");
    const [microserviceDescription, setMicroserviceDescription] = useState("");
    const [microserviceGithubUrl, setMicroserviceGithubUrl] = useState("");

    const setPopProps = (title , msg, color) =>{
        setPopUpMsg(msg);
        setPopUpTitle(title);
        setPopUpColor(color);
    }

    const [username, setUsername] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let repoName = microserviceGithubUrl.split("slazyslother/")[1].split("/")[0];
            const response = await fetch(`https://api.github.com/repos/slazyslother/${repoName}/collaborators/${username}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `token ${process.env.REACT_APP_TOKEN}`,
                    'Accept': 'application/vnd.github.v3+json',
                }
            });
            if (response.ok) {
                setPopProps("Hurray", "access granted", "green");
                handleOpen();
            } else {
                setPopProps("Alert", "access denied", "red");   // setPopProps("Alert" , "email format is not correct");
                handleOpen();
            }
        } catch (error) {
            setPopProps("Alert", "something went wrong", "red");   // setPopProps("Alert" , "email format is not correct");
            handleOpen();
        }

        setUsername('');
    }



    useEffect(() => {
        const url = window.location.href;
        const id = url.split('?').pop().split("=").pop();
        fetchMicroservice(id)
    }, []);

    const fetchMicroservice = async (id) => {
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/assignment/microservice?microserviceId=" + id);
        if( getResponse.status === "200" && getResponse["response"] != null ) {
            if(  getResponse["response"].length > 0 ){
                setMicroserviceTitle(getResponse["response"][0]["title"]);
                setMicroserviceDescription(getResponse["response"][0]["description"]);
                setMicroserviceGithubUrl(getResponse["response"][0]["githubUrl"]);
            }
        }

        // setApplications(getResponse["response"]);
    };


    return (
        <div className="container">
            <PopUp open={open} handleClose={handleClose} popUpTitle={popUpTitle} popUpMsg={popUpMsg} popUpColor={popUpColor} />
            <div className="content">
                <h1 className="title">{microserviceTitle}</h1>
                <p className="description">
                    {microserviceDescription}
                </p>
                <p className="github-url">
                    GitHub URL: <a href={microserviceGithubUrl} target="_blank" rel="noopener noreferrer">{microserviceGithubUrl}</a>
                </p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        style={{textAlign:"center"}}
                        id="usernameInput"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Your GitHub Username"
                        required
                    />
                    <button type="submit" style={{margin:"1%"}}>Get Permission</button>
                </form>
            </div>
        </div>
    );
};

export default Problem;
