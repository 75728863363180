import React, {useEffect, useState} from 'react';
import { FaArrowDown } from 'react-icons/fa';
import './SubApplication.css';
import {getRequest} from "../../Fetch/request";
import Loader from "../Loader/Loader";

const ButtonList = ({ microservices }) => {

    const handleSubmit = (id) =>{
        window.location.href =  "/problem?microserviceId=" + id;
    }

    return (
        <div className="button-list">
            {microservices.map((microservice, index) => (
                <React.Fragment key={index}>
                    <button className="button" onClick={()=>handleSubmit(microservice.id) }>{microservice.title}</button>
                    {index < microservices.length - 1 && <FaArrowDown className="arrow-icon" />}
                </React.Fragment>
            ))}
        </div>
    );
};

const Microservice = () => {
    const [subApplicationTitle, setSubApplicationTitle] = useState("");
    const [subApplicationDescription, setSubApplicationDescription] = useState("");
    const [microservices, setMicroservices] = useState([]);

    useEffect(() => {
        const url = window.location.href;
        const id = url.split('?').pop().split("=").pop();
        fetchAllMicroservices(id);
    }, []);

    const fetchAllMicroservices = async (id) => {
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/assignment/getMicroservices?subApplicationId=" + id);
        if( getResponse.status === "200" && getResponse["response"] != null ) {
            if(  getResponse["response"].length > 0 ){
                setSubApplicationTitle(getResponse["response"][0]["subAppTitle"]);
                setSubApplicationDescription(getResponse["response"][0]["subAppDescription"]);
            }
            setMicroservices(getResponse["response"]);
        }
    };
    return (
        (microservices.length > 0) ?
            (<div className="container">
            <div className="side-by-side">
                <div className="box">
                    <h2>{subApplicationTitle}</h2>
                    <br/>
                    <p className="description">{subApplicationDescription}</p>
                </div>
                <div className="box">
                    <ButtonList microservices={microservices} />
                </div>
            </div>
        </div>)
            :
            (<Loader/>)
    )
};

export default Microservice;

