import React, {useEffect, useState} from 'react';
import './Blog.css';

const Blog = ({ articleTitle, articleComponent }) => {
    return (
        <div className="blog-container">
            <header className="blog-header">
                <h1 className="blog-title">{articleTitle}</h1>
                <p className="blog-author">By : {localStorage.getItem('email')}</p>
            </header>
            {articleComponent.map((component, index) => {

                    return (<div key={index} className="blog-item" style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"white",padding:"20px"}}>
                            {component.text !== "" && <p className="blog-text">{component.text}</p>}
                            {component.text === "" &&
                                <img src={component.image} alt={`Image ${index}`} className="blog-image" style={{width:"40%" , height:"40%" , border:"1px solid black"}} />}
                        </div>
                    )
            })}
        </div>
    );
};

export default Blog;
