export async function uploadFileToS3(uploadUrl, file) {
    try {
        const response = await fetch(uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
                // Add any additional headers if needed
            },
        });

        if (response.ok) {
            console.log('File uploaded successfully.');
        } else {
            console.error('Failed to upload file. Status:', response.status);
        }
    } catch (error) {
        console.error('Error uploading file:', error.message);
    }
};