import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import ProfilePage from "./Components/Profile/Profile";
import Login from "./Components/Authorization/Login/Login";
import Note from "./Components/Notes/Note"
import MyBlogs from "./Components/Blog/MyBlogs"
import DiscussionForm from "./Components/Discussion/Discussion";
import HackathonPage from "./Components/Hackathon/HackathonPage";
import './App.css';
import Job from "./Components/Job/Job";
import Application from "./Components/Assignment/Application";
import SubApplication from "./Components/Assignment/SubApplication";
import Problem from "./Components/Assignment/Problem";
import Microservice from "./Components/Assignment/Microservice";
import Home from "./Components/Home/Home";
import CoreSubjects from "./Components/CoreSubjects/CoreSubjects";

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Retrieve authentication status from localStorage on component mount
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  useEffect(() => {
    // Update localStorage when isAuthenticated changes
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  const [id, setId] = useState(0);

  const setTheId = (id) => {
    setId(id);
  }

  const getTheId = () =>{
    return id;
  }


  return (
    <div>
    { isAuthenticated && localStorage.getItem("email") != null ? (
      <Router>
      <div
          className="app-container"
      >
           <Navbar  setIsAuthenticated={setIsAuthenticated} setTheId={setTheId}/>

        <div>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/core" element={<CoreSubjects/>} />
              <Route path="/dashboard" element={<ProfilePage />} />
              <Route path="/write" element={<Note setTheId={setTheId} getTheId={getTheId} id={id} />} />
              <Route path="/myNotes" element={<MyBlogs />} />
              <Route path="/forum" element={<DiscussionForm />} />
              <Route path="/hackathon" element={<HackathonPage/>} />
              <Route path="/job" element={<Job/>} />
              <Route path="/applications" element={<Application />} />
              <Route path="/subApplications" element={<SubApplication />} />
              <Route path="/microservices" element={<Microservice />} />
              <Route path="/problem" element={<Problem />} />
            </Routes>
        </div>
        {/*<Bottombar className="bottomBar"/>*/}
      </div>
    </Router>
    ) : (
      <div>
        <Router>
      <Routes>
          <Route exact path="/" element={ <Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="*" element={ <Login  setIsAuthenticated={setIsAuthenticated} />}/>
      </Routes>
        </Router>
      </div>
    )}
    </div>
  );
}

export default App;
