import React, { useState } from 'react';
import './ProblemTags.css'; // Import CSS file for styling

function ProblemTags({ tags, onSelectTags, type }) {
    const [selectedTags, setSelectedTags] = useState([]);

    // Function to handle tag selection
    const handleTagClick = (tag) => {
        if( type === "Difficulty" ){
            setSelectedTags([tag]);
            onSelectTags(tag);
        }else {
            const updatedTags = selectedTags.includes(tag)
                ? selectedTags.filter((t) => t !== tag)
                : [...selectedTags, tag];
            setSelectedTags(updatedTags);
            onSelectTags(updatedTags);
        }
    };

    return (
        <div className="problem-tags">
            <div className="tag-container">
                {tags.map((tag, index) => (
                    <button
                        key={index}
                        className={`${type==="Difficulty"?"level":"tag"} ${selectedTags.includes(tag.slug) ? 'selected' : ''}`}
                        onClick={() => handleTagClick(tag.slug)}
                    >
                        {tag.name}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default ProblemTags;
