import React, {useEffect, useState} from 'react';
import './Home.css';
import {FaArrowDown, FaArrowRight} from "react-icons/fa";
import ProblemTags from "./ProblemTags"; // Import CSS file for styling popup (optional)
import {getRequest} from "../../Fetch/request";
import {LineChart} from "@mui/x-charts";
import Loader from "../Loader/Loader";
import LinearIndeterminate from "../LinearLoader/LinearLoader";
import RefreshIcon from '@mui/icons-material/Refresh';

function Popup({ title, description, solutionLink, onClose }) {
    return (
        <div className="popup">
            <div className="popup-content">
                <h2>{title}</h2>
                <p>{description}</p>
                <a href={solutionLink} target="_blank" rel="noopener noreferrer">Solution Link</a>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

function UserProfile({ userAvatar, userName, userRating }) {
    return (
        <div className="user-profile">
            <div className="profile-image">
                <img src={userAvatar!=null?userAvatar:"/images/emptyLogo.png"} alt="User Logo" style={{height:"200px",width:"200px"}} />
            </div>
            <br/>
            <div className="profile-info">
                <div className="username">{userName}</div>
                <div className="user-rating">Rating: {userRating}</div>
            </div>
        </div>
    );
}
function ProblemTable({ problems, loader }) {
    return (
        <div className="table-container">
            <table className="problem-table">
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Link</th>
                </tr>
                </thead>
                <tbody>
                {loader ? (
                    <tr>
                        <td colSpan="2" className="loader-container">
                            <Loader />
                        </td>
                    </tr>
                ) : (
                    problems === null || problems.length === 0 ? (
                        <tr>
                            <td colSpan="2" style={{ textAlign: "center", padding: "20px" }}>
                                Add filters
                            </td>
                        </tr>
                    ) : (
                        problems.map((problem) => (
                            <tr key={problem.titleSlug}>
                                <td style={{ color: "black" }}>{problem.title}</td>
                                <td>
                                    <a href={`https://leetcode.com/problems/${problem.titleSlug}`}>Link</a>
                                </td>
                            </tr>
                        ))
                    )
                )}
                </tbody>
            </table>
        </div>
    );
}



function Home() {

    const [userAvatar, setUserAvator] = useState("");
    const [userName, setUserName] = useState("")
    const [userRating, setUserRating] = useState("");
    const [problems , setProblems] = useState(null);
    const [contestRating,setContestRating] = useState([]);
    const [contestTitle,setContestTitle] = useState([]);

    const [loader,setLoader] = useState(false);

    const [popupInfo, setPopupInfo] = useState(null);

    const showPopup = (title, description, solutionLink) => {
        setPopupInfo({ title, description, solutionLink });
    };

    const closePopup = () => {
        setPopupInfo(null);
    };

    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedDifficulty, setSelectedDifficulty] = useState('MEDIUM');
    const difficulty = [
        {
            "name": "Easy",
            "slug": "EASY"
        },
        {
            "name": "Medium",
            "slug": "MEDIUM"
        },
        {
            "name": "Hard",
            "slug": "HARD"
        }
    ]
    const tags = [
            {
                "name": "Array",
                "slug": "array"
            },
            {
                "name": "String",
                "slug": "string"
            },
            {
                "name": "Dynamic Programming",
                "slug": "dynamic-programming"
            },
            {
                "name": "Tree",
                "slug": "tree"
            },
            {
                "name": "Depth-first Search",
                "slug": "depth-first-search"
            },
            {
                "name": "Breadth-first Search",
                "slug": "breadth-first-search"
            },
            {
                "name": "Binary Search",
                "slug": "binary-search"
            },
            {
                "name": "Hash Table",
                "slug": "hash-table"
            },
            {
                "name": "Two Pointers",
                "slug": "two-pointers"
            },
            {
                "name": "Math",
                "slug": "math"
            },
            {
                "name": "Linked List",
                "slug": "linked-list"
            },
            {
                "name": "Stack",
                "slug": "stack"
            },
            {
                "name": "Heap",
                "slug": "heap"
            },
            {
                "name": "Greedy",
                "slug": "greedy"
            },
            {
                "name": "Sort",
                "slug": "sort"
            },
            {
                "name": "Bit Manipulation",
                "slug": "bit-manipulation"
            },
            {
                "name": "Backtracking",
                "slug": "backtracking"
            },
            {
                "name": "Design",
                "slug": "design"
            },
            {
                "name": "Trie",
                "slug": "trie"
            },
            {
                "name": "Union Find",
                "slug": "union-find"
            },
            {
                "name": "Graph",
                "slug": "graph"
            },
            {
                "name": "Recursion",
                "slug": "recursion"
            },
            {
                "name": "Queue",
                "slug": "queue"
            },
            {
                "name": "Divide and Conquer",
                "slug": "divide-and-conquer"
            },
            {
                "name": "Geometry",
                "slug": "geometry"
            },
            {
                "name": "Simulation",
                "slug": "simulation"
            },
            {
                "name": "String Matching",
                "slug": "string-matching"
            },
            {
                "name": "Concurrent",
                "slug": "concurrent"
            }
        ];

    const handleSelectTags = (tags) => {
        setSelectedTags(tags);
    };

    const handleSelectDifficulty = (difficulty) => {
        setSelectedDifficulty(difficulty);
    }

    useEffect(() => {
        fetchUserInfo();
        const fetchData = async () => {
            try {
                // Use Promise.all to execute both functions in parallel
                await Promise.all([
                    fetchUserCodingProfileDetails(),
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData()
    }, []);


    const fetchUserInfo = async () => {
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/user/getUser?email=" + localStorage.getItem("email"));
        console.log(getResponse);
        if( getResponse.status === "200" && getResponse["response"] != null && getResponse["response"].length > 0 ){
            let formObject = getResponse.response[0];
            localStorage.setItem("profileObject",JSON.stringify(formObject));
        }
    };

    const fetchProblemList = async () => {
        setProblems([]);
        setLoader(true);
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/leetcode/problems?tags=" + JSON.stringify(selectedTags) + "&limit=5&difficulty=" + selectedDifficulty);
        setLoader(false);
        if( getResponse != null ) {
            setProblems(getResponse.data.problemsetQuestionList.questions);
        }
    }

    const fetchUserCodingProfileDetails = async () => {
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/leetcode/userDetails?username=" + JSON.parse(localStorage.getItem("profileObject")).leetcode  );
        if( getResponse.data.matchedUser !== null ) {
            setUserAvator(getResponse.data.matchedUser.profile.userAvatar);
            setUserName(getResponse.data.matchedUser.username);
            setUserRating(getResponse.data.userContestRanking.rating);
            let filterContest = getResponse.data.userContestRankingHistory.filter(contest => {
                if (contest.attended) {
                    return contest;
                }
            })
            const filterContestRating = filterContest.map(contest => contest.rating);
            const filterContestTitle = filterContest.map(contest => contest.contest.title);
            setContestRating(filterContestRating);
            setContestTitle(filterContestTitle);
        }
    };

    useEffect(()=>{
    },[contestRating,contestTitle])


    return (
        <div className="Home">

            {/* Buttons on top left corner */}
            <div className="top-left">
                <button>Leet Code</button>
                {/*<button>Code Forces</button>*/}
            </div>

            {/* Highlighted div for upcoming contest on top right corner */}
            <div className="top-right">
                {/*<button*/}
                {/*        onClick={() => showPopup("Button 1", "Description for Button 1", "https://example.com/solution1")}>Puzzles*/}
                {/*</button>*/}
                {/*<button*/}
                {/*        onClick={() => showPopup("Button 2", "Description for Button 2", "https://example.com/solution2")}>Networking*/}
                {/*</button>*/}
                {/*<button*/}
                {/*        onClick={() => showPopup("Button 3", "Description for Button 3", "https://example.com/solution3")}>Operating*/}
                {/*    System*/}
                {/*</button>*/}
                {/*<button*/}
                {/*        onClick={() => showPopup("Button 4", "Description for Button 4", "https://example.com/solution4")}>DBMS*/}
                {/*</button>*/}
                {/*<button*/}
                {/*        onClick={() => showPopup("Button 5", "Description for Button 5", "https://example.com/solution5")}>Low Level Design*/}
                {/*</button>*/}
                {/*<button*/}
                {/*        onClick={() => showPopup("Button 6", "Description for Button 6", "https://example.com/solution6")}>High Level Design*/}
                {/*</button>*/}
                <button onClick={()=>{
                    window.location.href="https://leetcode.com/contest/";
                }}>Register For Upcoming Contest</button>
            </div>

            <div className="line-chart">
                {localStorage.getItem("profileObject") !== null && JSON.parse(localStorage.getItem("profileObject")).leetcode != null && JSON.parse(localStorage.getItem("profileObject")).leetcode !== "" ? (
                    contestTitle.length > 0 ? (
                        <LineChart
                    height={250}
                    series={[
                        { data: contestRating, label: 'Last Contests Performance'},
                    ]}
                    xAxis={[{ scaleType: 'point', data: contestTitle }]}
                    />):(<Loader/>)
                    )
                    :(
                    <div style={{width:"100%",textAlign:"center",fontWeight:"bold"}}>
                        <h2>Please Update Your LeetCode Username in DashBoard</h2>
                    </div>
                )}
            </div>

            {/* Response blocks */}
            <div className="response-block">

                {
                    localStorage.getItem("profileObject") !== null && JSON.parse(localStorage.getItem("profileObject")).leetcode != null && JSON.parse(localStorage.getItem("profileObject")).leetcode !== "" ? (
                        contestTitle.length > 0 ?
                            (<UserProfile userAvatar={userAvatar} userName={userName} userRating={userRating} />)
                            :(<Loader/>)
                    )
                    :(
                        (<UserProfile userAvatar={null} userName={null} userRating={userRating} />)
                    )
                }
            </div>

            <div className="response-block">
                <button onClick={fetchProblemList} style={{float:"right",marginRight:"4%"}}>  <RefreshIcon/></button>
                <ProblemTable problems={problems} loader={loader}/>
            </div>

            <div className="response-block">
                <ProblemTags tags={difficulty} onSelectTags={handleSelectDifficulty} type={"Difficulty"}/>
                <ProblemTags tags={tags} onSelectTags={handleSelectTags}/>
            </div>


            {popupInfo && <Popup {...popupInfo} onClose={closePopup}/>}

        </div>

    )
}

export default Home;
