import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

const PopupForm = ({ open, onClose, formData, handleChange, handleSubmit }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Fill out the form</DialogTitle>
            <DialogContent>
                <DialogContentText>Please enter your details:</DialogContentText>
                <TextField label="Name" name="name" value={formData.name} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Designation" name="designation" value={formData.designation} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Company" name="company" value={formData.company} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="College" name="college" value={formData.college} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="CGPA" name="cgpa" value={formData.cgpa} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Leetcode Username" name="leetcode" value={formData.leetcode} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Github Link" name="github" value={formData.github} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="Twitter Link" name="twitter" value={formData.twitter} onChange={handleChange} fullWidth margin="normal" />
                <TextField label="LinkedIn Link" name="linkedin" value={formData.linkedin} onChange={handleChange} fullWidth margin="normal" />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupForm;
