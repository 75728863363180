import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    useMediaQuery
} from '@mui/material';

const Quiz = (props) => {
    const { quizData } = props;
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    const isMediumScreen = useMediaQuery('(max-width: 1800px)');
    const [answers, setAnswers] = useState({});
    const [score, setScore] = useState(null);

    const handleOptionChange = (questionIndex, option) => {
        setAnswers({ ...answers, [questionIndex]: option });
    };

    const handleSubmit = () => {
        let calculatedScore = 0;
        quizData.forEach((question, index) => {
            if (answers[index] === question.options[question.correct_option.trim().charCodeAt(0) - 97]) {
                calculatedScore++;
            }
        });
        setScore(calculatedScore);
    };

    const getOptionColor = (question, option, index) => {
        const correctOption = question.options[question.correct_option.trim().charCodeAt(0) - 97];
        const selectedOption = answers[index];
        if (score !== null) { // Color options only after submission
            if (option === correctOption) {
                return 'green'; // Correct answer
            } else if (option === selectedOption) {
                return 'red'; // Incorrectly selected answer
            }
        }

        return 'black'; // Default color before submission
    };

    return (
        <Box mt={5} style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {score !== null && quizData.map((question, index) => (
                <div key={index} style={{ marginBottom: '20px', backgroundColor: "#eee", width: isSmallScreen ? "100%" : (isMediumScreen ? "50%" : "20%") }}>
                    <CardContent>
                        <Typography variant="h5" component="div" style={{ width: "100%" }}>
                            {question.question}
                        </Typography>
                        <FormControl component="fieldset" style={{ marginTop: '10px' }}>
                            <RadioGroup
                                value={answers[index] || ''}
                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                disabled={score !== null} // Disable selection after submission
                            >
                                {question.options.map((option, i) => (
                                    <FormControlLabel
                                        key={i}
                                        value={option}
                                        control={<Radio />}
                                        label={option}
                                        style={{ color: getOptionColor(question, option, index) }} // Apply color based on correctness
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </div>
            ))}
            {score === null && quizData.map((question, index) => (
                <div key={index} style={{ marginBottom: '20px', backgroundColor: "#eee", width: isSmallScreen ? "100%" : (isMediumScreen ? "50%" : "20%") }}>
                    <CardContent>
                        <Typography variant="h5" component="div" style={{ width: "100%" }}>
                            {question.question}
                        </Typography>
                        <FormControl component="fieldset" style={{ marginTop: '10px' }}>
                            <RadioGroup
                                value={answers[index] || ''}
                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                disabled={score !== null} // Disable selection after submission
                            >
                                {question.options.map((option, i) => (
                                    <FormControlLabel
                                        key={i}
                                        value={option}
                                        control={<Radio />}
                                        label={option}
                                        // Apply color based on correctness
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </div>
            ))}
            {score !== null && <Typography variant="h6" component="div" style={{ marginTop: '20px' }}>
                Your Score: {score}/{quizData.length}
            </Typography>}
            <Button onClick={handleSubmit} variant="contained" color="primary">
                Submit
            </Button>
        </Box>
    );
};

export default Quiz;
